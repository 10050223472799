import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import CourseDetailsContainer from 'modules/courses/containers/CourseDetailsContainer';

const CourseDetailsPage = (props) => (
  <CourseDetailsContainer
    course={props.data.fugoki.course}
    courseSlug={props.pageContext.courseSlug}
    trackSlug={props.pageContext.trackSlug}
  />
);

CourseDetailsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default CourseDetailsPage;

export const pageQuery = graphql`
  query courseQuery($courseId: ID!) {
    fugoki {
      course(id: $courseId) {
        id
        status
        description
        skillz
        level
        name
        slug
        trackstepSet {
          edges {
            node {
              id
              track {
                id
                slug
                name
              }
            }
          }
        }
        teachers {
          edges {
            node {
              id
              bio
              avatar {
                url
              }
              firstName
              lastName
            }
          }
        }
        stageSet {
          edges {
            node {
              id
              name
              description
              position
              slug
              stepSet {
                edges {
                  node {
                    id
                    isLocked
                    slug
                    name
                    position
                    duration
                    status
                    videotutorial {
                      id
                    }
                    note {
                      id
                    }
                    project {
                      id
                    }
                    quiz {
                      id
                    }
                    codechallenge {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
